.box1 {
  display: flex; 
  box-shadow: -37px 0 23px -8px rgba(107, 107, 107, 0.1), 0 0 40px -8px rgba(107, 107, 107, 0.1);

  &__left {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    padding: 5.6rem;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background-image: url('../../assets/images/cocktailsguy-fond.jpg');
      background-size: cover;
      z-index: -1;
      opacity: 0.08;
    }

    h2 {
      margin-bottom: 5.6rem;
      line-height: 1.2;
    }
  }
}

.bannerGreen {
  margin-top: 8rem;
  padding: 8rem 0;
  position: relative;
  background: $green-medium;
  background: -moz-radial-gradient(center, ellipse cover,  $green-medium 0%, $green-dark 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  $green-medium 0%,$green-dark 100%);
  background: radial-gradient(ellipse at center,  $green-medium 0%,$green-dark 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green-medium', endColorstr='$green-dark',GradientType=1 );

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../../assets/images/cocktailsguy-fond.jpg');
    animation: animBg 30s alternate linear infinite;
    background-position: 50% 50%;
    opacity: 0.03;
  }
}

.boxGoldenBorders {
  color: #ffffff;
  text-align: center;
  padding: 8rem;
  position: relative;
  max-width: 800px;
  margin: auto;

  p {
    font-weight: 300;
    margin-top: 1rem;
  }

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 0.5s 0.5s cubic-bezier(.45,.84,.4,.99);

    &.linesInner {padding: 0.6rem;}
  }
}

@media(max-width: 800px) {
  .box1 {
    display: block;
  }
}

@media(max-width: 767px) {
  .box1 {
    &__right {
      padding: 3.2rem;

      h2 {
        margin-bottom: 3.2rem;
        line-height: 1.2;
      }
    }
  }

  .bannerGreen {
    margin-top: 4rem;
    padding: 4rem 0;
  }

  .boxGoldenBorders {
    color: #ffffff;
    text-align: center;
    padding: 4rem;
    position: relative;
    max-width: 800px;
    margin: auto;
  }
}