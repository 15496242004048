.bigTitle {
  text-align: center;
  margin-bottom: 8rem;

  img {
    width: 32px;
  }

  h1 {
    max-width: 650px;
    margin: auto;
  }

  @media(max-width: 1024px) {
    margin-bottom: 5.6rem;
  }

  @media(max-width: 767px) {
    margin-bottom: 4rem;

    img {
      width: 28px;
      margin-bottom: 1rem;
    }
  }

  @media(max-width: 375px) {
    img {
      width: 24px;
    }
  }
}