* {
  box-sizing: border-box;
  scrollbar-color: $gold-medium transparent;
  scrollbar-width: thin;
}

*:focus {outline:none !important}


html {
  font-size: 62.5%;
}

html, body {
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: 1.6rem;
}


html, body, p {
  font-family: $font-family-text;
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: $font-family-heading;
  font-weight: 400;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {color: $gold-medium;}

img {max-width: 100%;}


input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


@media(hover: hover) {
  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: none;
    background-color: #ffffff;
  } 

  ::-webkit-scrollbar
  {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb
  {
    background-color: $gold-medium;
    border: 0
  }
}

@media(max-width: 360px) {
  body {
    font-size: 1.4rem;
  }
}