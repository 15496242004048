.button {
  border: 1px solid $gold-medium;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 1.6rem;
  position: relative;
  transition: 0.2s;
  
  color: $gold-medium;
  cursor: pointer;

  &:focus {outline: 0}

  &.-icon {
    padding-left: 6rem;
  }

  &.-primary {
    background-color: $gold-medium;
    color: #ffffff;
  }

  &:disabled {
    pointer-events: none;
    border: 1px solid #cccccc;
    color: #cccccc;


    &.-primary {
      background-color: #cccccc;
    }
  }

  &.-fullWidth {
    width: 100%;
    justify-content: center;
  }

  @media(hover: hover) {
    &:hover {
      background-color: $gold-medium;
      color: #ffffff;
    }
  }


  span {
    position: absolute;
    left: 0;
    background-color: #ffffff;
    padding: 0.9rem 1rem;
    
    img {
      width: 30px;
      display: block;
    }
  }
  
  @media(max-width: 767px) {
    letter-spacing: 0.2rem;
  }
}

.buttonFilter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 1.5rem;
  background-color: $gold-medium;
  color: #ffffff;
  border: 0;
  display: none;
  opacity: 0;
  transform: translateY(3rem);

  @media(max-width: 767px) {
    display: block;
    animation: animButtonFilter 1s 1s forwards
  }
}

@keyframes animButtonFilter {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}