h1 {
  text-align: center;
  font-size: 4.8rem;
  line-height: 1.2;

  span {
    color: $gold-medium;
  }
}

h2 {
  font-size: 4rem;

  span {
    color: $gold-medium;
  }
}

p {
  margin-bottom: 3.2rem;
  line-height: 1.6;
  &:last-child {margin-bottom: 0;}
}

@media(max-width: 767px) {
  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
  }
}

@media(max-width: 360px) {
  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}