.modalContainer {
  background-color: rgba($green-medium, 0.8);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  overflow: auto;
  transition: 0.3s;

  > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border: 0;
    cursor: pointer;
    img {
      width: 24px;
    }
  }

  .modalContainer__modal {
    max-width: 1000px;
    width: 100%;
    padding: 0 2rem;
    -webkit-overflow-scrolling: touch;
  }

  .modalCloseMobile {
    display: none;
  }

  @media(max-width: 767px) {
    align-items: flex-start;
    background-color: rgba($green-medium, 1);
    transition: 0s;

    > button {
      z-index: 1;
      width: 60px;
      height: 60px;
      background-color: transparent;
      img {width: 32px;height:32px;}
    }
    
    .modalContainer__modal {
      padding: 0 0;
    }

    .modalCloseMobile {
      display: block;
      border: 0;
      background-color: $gold-medium;
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 1.5rem;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1.8rem;
      letter-spacing: 0.5rem;    

      img {display: none;}
    }
  }
}

.-showModal {
  min-height: 100vh;
  overflow-y: hidden;

  .modalContainer {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
  }
}
