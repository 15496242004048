.recipe {
  display: flex;
  transition: 0.5s;
  box-shadow: none;

  .recipe__image {
    flex: 0 0 50%;
    max-width: 50%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .recipe__content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5rem;
    position: relative;
    background-color: #ffffff;
    z-index: 1;
    
    &:after {
      background-image: url('../assets/images/cocktailsguy-fond.jpg');
      background-position: center center;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0.05;
    }

    h2 {
      font-size: 4.8rem;
      position: relative;
      padding-bottom: 4rem;
      margin-bottom: 4rem;

      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100px;
        height: 1px;
        background-color: $gold-medium;
      }
    }

    ul {
      margin-bottom: 4rem;
      li {
        position: relative;
        font-size: 1.6rem;
        padding-left: 3rem;
        margin-bottom: 1.6rem;

        &:before {
          background-image: url('../assets/images/cocktailsguy-petale.svg');
          background-repeat: no-repeat;
          background-size: 12px;
          transform: rotate(-35deg);
          width: 30px;
          height: 30px;
          content: "";
          position: absolute;
          top: -10px;
          left: 0;
        }

        &:last-child {margin-bottom: 0;}
      }
    }
  }
}

@media(max-width: 767px) {
  .recipe {
    flex-direction: column;

    .recipe__image, .recipe__content {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .recipe__image {
      transform: translateY(-100%) translateX(0);
    }

    .recipe__content {
      transform: translateY(100%) translateX(0);
      padding: 3rem 3rem 9rem 3rem;
      h2 {
        font-size: 3rem;
        margin-bottom: 3rem;
        padding-bottom: 3rem;
      }
    }
  }
}

@keyframes animRecipeLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }

  25% {opacity: 0;}

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animRecipeRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }

  25% {opacity: 0;}

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animRecipeTop {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animRecipeBottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  25% {opacity: 0;}

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes animBoxShadow {
  0% {
    box-shadow: none;
  }

  100% {
    box-shadow: 0 0 54px -4px rgba(0, 0, 0, 0.3);
  }
}


.-showModal {
  .recipe {
    animation: animBoxShadow 0.3s 0.3s forwards cubic-bezier(.14,.71,.59,.97);
    
    .recipe__image {
      animation: animRecipeLeft 0.5s forwards cubic-bezier(.14,.71,.59,.97);
    }
    .recipe__content {
      animation: animRecipeRight 0.5s forwards cubic-bezier(.14,.71,.59,.97);
    }
  }

  @media(max-width: 767px) {
    .recipe {
      animation: none;
      
      .recipe__image {
        animation: animRecipeTop 0.5s forwards cubic-bezier(.14,.71,.59,.97);
      }
      .recipe__content {
        animation: animRecipeBottom 0.5s forwards cubic-bezier(.14,.71,.59,.97);
      }
    }
  }
}


.-hideModal {
  .recipe {
    animation: animBoxShadow 0.3s 0.3s forwards cubic-bezier(.14,.71,.59,.97);
    
    .recipe__image {
      animation: animRecipeLeft 0.5s forwards reverse cubic-bezier(.14,.71,.59,.97);
    }
    .recipe__content {
      animation: animRecipeRight 0.5s forwards reverse cubic-bezier(.14,.71,.59,.97);
    }
  }

  @media(max-width: 767px) {
    .recipe {
      animation: none;
      
      .recipe__image {
        animation: animRecipeTop 0.5s forwards cubic-bezier(.14,.71,.59,.97);
      }
      .recipe__content {
        animation: animRecipeBottom 0.5s forwards cubic-bezier(.14,.71,.59,.97);
      }
    }
  }
}


.cocktailsGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8rem;

  .cocktailsGrid__column {
    padding: 0.8rem;
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media(max-width: 767px) {
    margin: 0 -1.6rem 5rem;
    .cocktailsGrid__column {
      padding: 0rem;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}