.cocktailCard {
  cursor: pointer;
  position: relative;
  height: 100%;
  padding-top: 100%;

  .cocktailCard__fakeLoader {
    background-color: red;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    bottom: 0;
  }

  > img {
    display: block;
    position: absolute;
    top: 0;
  }

  .cocktailCard__hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green-medium;
    opacity: 0;
    transform: scale(1.05);
    transition: 0.2s ease-out;

    &.-show {opacity: 1;transform: scale(1);}

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    img {
      width: 45px; 
      margin-left: -7px;
      margin-bottom: 1rem;
    }

    span {
      text-transform: uppercase;
      color: $gold-medium;
      letter-spacing: 0.5rem;
    }
  }



  @media(hover: hover) {
    
    &:hover .cocktailCard__hover {
      opacity: 1;
      transform: scale(0.9999);
    }

    &:hover .cocktailCard__hover.-show + .cocktailCard__hover{
      opacity: 0!important;
      transform: scale(0.9999);
    }
  }

  @media(max-width: 767px) {
    .cocktailCard__hover {
      transform: scale(1);

      img {width: 32px;}

      span {font-size: 1rem;}
    }
  }
}