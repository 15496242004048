.fullscreen {
  height: 100vh;
  position: relative;
  align-items: center;
  overflow: hidden;
  background: $green-medium;
  background: -moz-radial-gradient(center, ellipse cover,  $green-medium 0%, $green-dark 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  $green-medium 0%,$green-dark 100%);
  background: radial-gradient(ellipse at center,  $green-medium 0%,$green-dark 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green-medium', endColorstr='$green-dark',GradientType=1 );
  transition: 0.5s 0s cubic-bezier(.45,.84,.4,.99);
  will-change: height;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../../assets/images/cocktailsguy-fond.jpg');
    animation: animBg 30s alternate linear infinite;
    background-position: 50% 50%;
    opacity: 0.03;
  }

  &__header {
    position: absolute;
    left: 0;
    right: 0;
    top: 7.5rem;
    text-align: center;
    color: #ffffff;
    font-size: 3rem;
    line-height: 2.7rem;
    font-family: $font-family-heading;
    opacity: 0;
    animation: animHeader 0.8s 0s forwards cubic-bezier(.24,.64,.47,.99);

    span {color: $gold-medium;}
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    padding: 0 2rem;
    transform: translateY(-50%);

    .cocktails {
      display: flex;
      justify-content: space-around;
      color: #ffffff;
      position: absolute;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-family: $font-family-heading;
      font-size: 12vw;
      width: 100%;
      max-width: 2000px;
      text-transform: uppercase;
      opacity: 0.03;
      z-index: -1;
      pointer-events: none;
      transition: 0.3s 0.5s cubic-bezier(.45,.84,.4,.99);

      & > span:first-child {
        padding-left: 0;
      }

      & > span:last-child {
        padding-right: 0;
      }

      span {
        transform: scaleX(-1);
        opacity: 0;
        display: block;
      }

      
      span:nth-child(1) {animation: flipLetter 0.5s 1.5s forwards ease-out;}
      span:nth-child(2) {animation: flipLetter 0.5s 1.6s forwards ease-out;}
      span:nth-child(3) {animation: flipLetter 0.5s 1.7s forwards ease-out;}
      span:nth-child(4) {animation: flipLetter 0.5s 1.8s forwards ease-out;}
      span:nth-child(5) {animation: flipLetter 0.5s 1.9s forwards ease-out;}
      span:nth-child(6) {animation: flipLetter 0.5s 2s forwards ease-out;}
      span:nth-child(7) {animation: flipLetter 0.5s 2.1s forwards ease-out;}
      span:nth-child(8) {animation: flipLetter 0.5s 2.2s forwards ease-out;}
      span:nth-child(9) {animation: flipLetter 0.5s 2.3s forwards ease-out;}
    }

    .cardHighlight1 {
      //border: 1px solid $gold-medium;
      text-align: center;
      max-width: 1000px;
      margin: auto;
      position: relative;
      padding: 10rem 19rem;
      transition: 0.5s 0.5s cubic-bezier(.45,.84,.4,.99);
    
      &__content {

        > a {display: block;}
       
        img {
          width: 220px;
          opacity: 0;
          animation: animLogo 0.8s 0.5s forwards;
        }
       
        h1 {
          color: #ffffff;
          text-align: center;
          font-size: 3rem;
          margin-top: 3rem;
          margin-bottom: 1.6rem;
          line-height: 3.6rem;
          opacity: 0;
          transform: translateY(-3rem);
          animation: animTitle 1s 0.5s forwards cubic-bezier(.24,.64,.47,.99);
          span {color: $gold-medium;}
        } 
      
        > span {
          color: #ffffff;
          text-transform: uppercase;
          letter-spacing: 0.5rem;
          font-size: 1.4rem;
          display: block;
          opacity: 0;
          transform: translateY(-2rem);
          animation: animTitle 1s 0.7s forwards cubic-bezier(.24,.64,.47,.99);
        }
      }
    
      svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: 0.5s 0.5s cubic-bezier(.45,.84,.4,.99);
    
        &.linesInner {padding: 0.6rem;}
      }
    }
  } 
 
  &__nav {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 7.5rem;
    transform: translateY(30%);
    opacity: 0;
    visibility: hidden;
    animation: animNav 0.8s 2s forwards cubic-bezier(.24,.64,.47,.99);

    nav {
      ul {
        li {
          margin: 0 2rem;
          display: inline-flex;
          a {
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            color: #ffffff;
            letter-spacing: 0.5rem; 
            transition: 0.2s;
            position: relative;
            padding: 1rem 0.5rem;

            &:after {
              position: absolute;
              content: "";
              width: 100%;
              height: 1px;
              left: 0;
              right: 0;
              bottom: -0.5rem;
              transform: scaleX(0);
              background-color: $gold-medium;
              transition: 0.2s;
            }

            &:hover, &.active {
              color: $gold-medium;
              &:after {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
    
  }

  @media(max-height: 850px) and (orientation: landscape) {
    &__header {
      top: 2rem;
    }

    &__nav {
      bottom: 4rem;
    }
  }

  @media(max-width: 800px) {
    .cardHighlight1 {
      padding: 10rem 7rem;
    }
  }

  @media(max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100);
    
    &__header {
      top: 8%;
    }

    .cardHighlight1 {
      padding: 7rem 3rem;

      &__content {
        img {
          width: 150px;
        }
  
        h1 {
          font-size: 2rem;
        }
    
        > span {
          font-size: 1.2rem;
        }
      }
    }

    &__nav {
      bottom: 12%;
      nav {
        ul {
          li {
            margin: 0 1rem;
            a {
              font-size: 1.4rem;
              padding: 1rem 0;
            }
          }
        }
      }
    }
  }

  @media(max-width: 375px) {

    &__header {
      top: 6%;
      font-size: 2.6rem;
    }

    .cardHighlight1 {
      padding: 3rem 3rem;
    
      &__content {
        img {
          width: 150px;
        }
  
        h1 {
          margin-top: 1rem;
          font-size: 2rem;
          line-height: 3rem;
        }
    
        > span {
          font-size: 1.2rem;
        }
      }
    }

    &__nav {
      bottom: 12%;
      nav {
        ul {
          li {
            margin: 0 1rem;
            a {font-size: 1.4rem;}
          }
        }
      }
    }
  }

  @media(max-width: 360px) {

    .cardHighlight1 {
      padding: 3rem 3rem;
    
      &__content {
        img {
          width: 120px;
        }
  
        h1 {
          margin-top: 1rem;
          font-size: 1.8rem;
          line-height: 3rem;
        }
    
        > span {
          font-size: 1.2rem;
        }
      }
    }

    &__header {
      top: 7%;
      font-size: 2.4rem;
    }

    &__nav {
      bottom: 13%;
      nav {
        ul {
          li {
            margin: 0 1rem;
            a {font-size: 1.3rem;}
          }
        }
      }
    }
  }
}

@keyframes animLogo {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animTitle {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes heightZeroTitle {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

@keyframes flipLetter {
  0% {
    opacity: 0;
    transform: scaleX(-1);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes animBg {
  0% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 100% 100%;
  }
}

@keyframes animHeader {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  50% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animNav {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  50% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.-animating {
  .fullscreen {
    height: 300px;
    transition: 0.5s 0.8s cubic-bezier(.45,.84,.4,.99)!important;
    
    &__header {
      transform: translateY(-100%);
      opacity: 0;
      transition: 0.4s;
      animation: none;
    }
  
    &__content {
      .cocktails {
        transform: translateY(-50%) translateX(-50%) scale(0.8);
        opacity: 0;
        transition: 0.3s 0.5s cubic-bezier(.45,.84,.4,.99);
      }

      .cardHighlight1 {
        padding: 0;
        transition: 0.5s 0s cubic-bezier(.45,.84,.4,.99)!important;
      
        &__content {
          img {
            width: 175px;
            transition: 0.3s 1s;
            margin-top: -5rem;
          }
      
          h1 {
            animation: none!important;
            margin: 0;
            line-height: 0;
            opacity: 0!important;
            transition: 0.5s;
          }
      
          > span {
            animation: none!important;
            margin: 0;
            line-height: 0;
            opacity: 0!important;
            transition: 0.5s;
          }
        }
      
        svg {
          opacity: 0;
          transition: 0.5s 0s cubic-bezier(.45,.84,.4,.99);
        }
      }
    }
  
    &__nav {
      bottom: 2.4rem;
      transition: 0.3s 1s;
    }
  
    @media(max-width: 767px) {
      height: 200px;

      &__content {
        .cardHighlight1 {
          &__content {
            img {
              width: 100px;
              margin-top: -3rem;
            }
          }
        }
      }
  
      &__nav {
        bottom: 2rem;
        transition: 0.3s 1s;
  
        nav {
          ul {
            li {
              a {
                &.-current:after, &.-hover:after {content: none;}
              }
            }
          }
        }
      }
    }
  }
}

.-animated {
  .fullscreen {
    height: 300px;

    &__header {
      opacity: 0;
      transition: 0s;
      animation: none;
    }

    &__content {
      .cocktails {
        opacity: 0;
        visibility: hidden;
        transition: 0s;
      }
    }

    &__nav {
      bottom: 2.4rem;
      opacity: 1;
      transition: 0s 0s;
      animation: none;
      visibility: visible;
      transform: translateY(0);
    }

    .cardHighlight1 {
      &__content {
        img {
          width: 175px;
          margin-top: -5rem;
          transition: 0s 0s;
          animation: none;
          opacity: 1;
          transform: scale(1)
        }
        
        h1 {
          animation: none!important;
          margin: 0;
          line-height: 0;
          opacity: 0!important;
          transition: 0s;
          visibility: hidden;
        }
    
        > span {
          animation: none!important;
          margin: 0;
          line-height: 0;
          opacity: 0!important;
          transition: 0s;
          visibility: hidden;
        }
      }

      svg {
        opacity: 0!important;
      }
    }

    @media(max-width: 767px) {
      height: 200px;

      &__content {
        .cardHighlight1 {
          &__content {
            img {
              width: 100px;
              margin-top: -3rem;
            }
          }
        }
      }

      &__nav {
        bottom: 2rem;
        transition: 0.3s 1s;
        nav {
          ul {
            li {
              a {
                &.-current:after, &.-hover:after {content: none;}
              }
            }
          }
        }
      }
    }
  }
}


.-backToHome {
  .fullscreen {
    height: 100vh;

    &__nav {
      opacity: 1!important;
      visibility: visible!important;
      transform: translateY(0)!important;
    }
  }
}