.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $green-medium;
  opacity: 1;
  transform: scale(1);
  transition: 0.2s ease-out;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 45px; 
      margin-left: -7px;
      margin-bottom: 1rem;
    }
  }

  span {
    text-transform: uppercase;
    color: $gold-medium;
    letter-spacing: 0.5rem;
  }

  @media(max-width: 767px) {
    > div {
      img {
        width: 32px;
      }
    }

    span {
      font-size: 1.2rem;
      letter-spacing: 0.2rem;
    }
  }

  @media(max-width: 320px) {
    > div {
      img {
        width: 24px;
      }
    }

    span {
      font-size: 1.2rem;
      letter-spacing: 0.2rem;
    }
  }
}