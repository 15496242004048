.overlayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s cubic-bezier(.14,.71,.59,.97);
  background-color: rgba($green-medium,0);
  z-index: -1;
}

body.-showSearchForm {
  .overlayer {
    background-color: rgba($green-medium, 0.9);
    z-index: 1;
    top: -100%;
  }
}