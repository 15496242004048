.content {
  padding: 8rem 0;

  @media(max-width: 1024px) {
    padding: 5.6rem 0;
  }

  @media(max-width: 767px) {
    padding: 4rem 0;
    
  }
}