.searchForm {
  position: relative;
  padding: 8rem 8rem 4rem;
  margin-bottom: 8rem;
  box-shadow: none;
  -webkit-overflow-scrolling: touch;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../../assets/images/cocktailsguy-fond.jpg');
    background-position: 50% 50%;
    opacity: 0.03;
    z-index: -1;
  }

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;

    &.linesInner {padding: 0.6rem;}
  }

  form {
    display: flex;

    flex-wrap: wrap;
    margin: 0 -1.6rem;

    > div {
      padding: 0 1.6rem;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      margin-bottom: 3.2rem;
    }

    > .buttonFilterMobile {
      display: none;
    }

    .choices {
      box-shadow: 0 0 25px -9px rgba(0,0,0,0.1);
      

      &[data-type*='select-one']:after {
        border-color: $gold-medium transparent transparent;
        right: 2rem;
        border-width: 7px;
      }

      &[data-type*='select-one'].is-open:after {
        margin-top: -1rem;
        transform: rotate(180deg);
      }

      .choices__inner {
        border: 0;
        background-color: transparent;
        padding: 1.5rem;
        font-size: 1.6rem;

        .choices__list {
          .choices__placeholder {
            color: #6f6f6f;
            opacity: 1;
            text-align: left;
          }
        }
      }

      .choices__inner + .choices__list {
        border: 0;
        box-shadow: 0 0 25px -9px rgba(0,0,0,0.1);
      }

      .choices__list--dropdown  { 
        transform: translateY(-3rem);
        opacity: 0;
        transition: 0.3s;
        .choices__item--selectable {
          padding-right: 0!important;
        }

        .choices__item {
          padding: 1.6rem;
          text-align: left!important;
        }

        &.is-active {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .choices__item--selectable {
        text-align: left;
      }
    }
  }

  @media(max-width: 1024px) {
    padding: 5.6rem 5.6rem 3rem 5.6rem;
    margin-bottom: 5.6rem;
  }

  @media(max-width: 767px) {
    margin: 0 0;
    padding: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #ffffff;
    transform: translateY(100vh);
    transition: 1.5s cubic-bezier(.14,.71,.59,.97);
    height: auto;
    max-height: 0;
    overflow: visible;
    
    &__content {
      padding: 4rem 3rem 3rem 3rem;
      background-color: #ffffff;
      position: relative;
      z-index: 2;
    }

    svg {display: none;}

    form {
      margin: 0;
      > div {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 2rem;
        &:last-child {margin-bottom: 0;}
      }

      > .buttonFilterMobile {
        display: block;
      }
    }
  }

  @media(max-width: 360px) { 
    &__content {
      padding: 2rem;
    }
  }
}


body.-showSearchForm {
  
  .searchForm {
    animation: animBoxShadow 0.3s forwards cubic-bezier(.14,.71,.59,.97);

    @media(max-width: 767px) {
      transform: translateY(0);
      transition: 0.5s cubic-bezier(.14,.71,.59,.97);
      max-height: 100vh;
    }
  }
}