.message {
  padding: 4.8rem;
  text-align: center;
  background: $green-medium;
  background: -moz-radial-gradient(center, ellipse cover,  $green-medium 0%, $green-dark 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  $green-medium 0%,$green-dark 100%);
  background: radial-gradient(ellipse at center,  $green-medium 0%,$green-dark 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green-medium', endColorstr='$green-dark',GradientType=1 );

  img {
    width: 32px;
    margin-bottom: 2rem;
  }

  p {
    font-family: $font-family-heading;
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
  }

  &.-error {
    background-color: #c73333;
    color: #ffffff;
  }

  @media(max-width: 767px) {
    padding: 4rem;

    img {width: 24px;}

    p {
      letter-spacing: 0.1rem;
      line-height: 1.4;
      font-size: 1.6rem;
    }
  }
}