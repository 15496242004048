.selectMobile {
  position: relative;
  select {
    width: 100%;
    border: 0;
    padding: 1.6rem;
    box-shadow: 0 0 25px -9px rgba(0,0,0,0.1);
    font-size: 1.6rem;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    -webkit-appearance: none;
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url('../../assets/images/cocktailsguy-arrow.svg');
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    width: 51px;
    height: 51px;
    background-size: 16px;
    background-position: center center;
    background-color: #ffffff;
  }
}

.searchForm form .choices {
  background-color: #ffffff;
}